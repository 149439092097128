<template>
	<div>
		<vue-progress-bar />
		<!-- Airdrop default page and referral -->
		<div v-show="!loadingPage">
			<div v-if="!customInvite">
				<Hero
					:referredByName="referredByName"
					:referredBy="referredBy"
					:airdropAmount="airdropAmount"
					:referralBonus="referralBonus"
					:customInvite="customInvite"
					:inviteMessage="inviteMessage"
				/>
				<HowItWorks :customInvite="customInvite" />
				<Footer
					:disclaimerContent="2"
					:emptyFooter="referredBy !== '' || customInvite !== ''"
					:airdropPage="true"
				/>
			</div>
			<div v-else>
				<!-- Custom Invite EN -->
				<div v-if="lang === 'en'">
					<CustomInvite
						:airdropAmount="airdropAmount"
						:referralBonus="referralBonus"
						:customInvite="customInvite"
						:inviteMessage="inviteMessage"
					/>
				</div>
				<!-- Custom Invite EN -->
				<div v-else-if="lang === 'ru'">
					<CustomInviteRussian
						:airdropAmount="airdropAmount"
						:referralBonus="referralBonus"
						:customInvite="customInvite"
						:inviteMessage="inviteMessage"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Hero from "@/components/airdrop/Hero";
import HowItWorks from "@/components/airdrop/HowItWorks";
import Footer from "@/components/partials/Footer";
import { i18n } from "../plugins/i18n";
import Cookie from "js-cookie";
import CustomInvite from "./CustomInvite.vue";
import CustomInviteRussian from "./CustomInviteRussian.vue";
import Invites from "../services/Invites";

export default {
	name: "Airdrop",
	components: {
		Hero,
		HowItWorks,
		Footer,
		CustomInvite,
		CustomInviteRussian,
	},
	data() {
		return {
			loadingPage: true,
			referredBy: "",
			customInvite: "",
			inviteMessage: "",
			referredByName: "",
			airdropAmount: 0,
			referralBonus: 0,
			lang: "en",
			headParams: {
				title: this.$t("meta.AIRDROP_TITLE"),
				description: this.$t("meta.AIRDROP_DESC"),
			},
			custom_invite_list: null,
		};
	},
	async created() {
		if (!this.custom_invite_list || this.custom_invite_list.length < 1) {
			try {
				const data = await Invites.getInviteList();
				this.custom_invite_list = data.data;
			} catch (err) {
				this.custom_invite_list = [];
			}
		}
		// Get Referral ID
		if (this.$route.params.referred_by) {
			this.referredBy = this.$route.params.referred_by.toLowerCase();
		}
		// Load the custom invite from the url parameters
		if (this.$route.params.custom_invite) {
			this.customInvite = this.$route.params.custom_invite.toLowerCase();

			// If custom invite does not exist, redirect user to homepage
			if (!this.customInviteContent("exists").exists) {
				this.$router.replace("/");
				return;
			} else {
				i18n.locale = this.customInviteContent("exists").lang;
				Cookie.set("locale", this.customInviteContent("exists").lang);
				this.lang = this.customInviteContent("exists").lang;
				// Load custom message for Hero page
				this.inviteMessage = this.customInviteContent("message");
			}
		}

		if (this.referredBy) {
			localStorage.setItem("airdrop_referred_by", this.referredBy);
			localStorage.removeItem("airdrop_custom_invite");
		}
		if (this.customInvite) {
			localStorage.setItem("airdrop_custom_invite", this.customInvite);
			localStorage.removeItem("airdrop_referred_by");
		}
	},
	async mounted() {
		this.$Progress.start();

		this.airdropAmount = 100;
		this.referralBonus = 50;

		this.loadingPage = false;
		this.$Progress.finish();

		document.dispatchEvent(new Event("x-app-rendered"));
	},
	methods: {
		// Custom URLs for Airdrop Invites
		// Key matches to url (ie /fractal) and value is message for Hero subtitle
		customInviteContent(type) {
			// const customGroups = {
			//   // 'fractal': 'CUSTOM',
			//   quorainnercircle: "CUSTOM",
			//   cryptouniverse: "CUSTOM",
			//   alexcrypto: "CUSTOM",
			//   keybase: "CUSTOM",
			//   reddit: "Hey Reddit, welcome to the Morpher Airdrop!",
			//   tallguytycoon: "Tall Guy Tycoon invited you to the Morpher Airdrop!",
			//   cryptobuzz: "CryptoBuzz invited you to join the Morpher Airdrop!",
			//   tehmoonwalker: "tehMoonwalkeR invited you to the Morpher Airdrop!",
			//   cryptoexplorer: "CryptoExplorer invited you to the Morpher Airdrop!",
			//   tokenomi: "Tokenomi invited you to the Morpher Airdrop!",
			//   cryptoanalyzer: "Crypto Analyzer invited you to the Airdrop!",
			//   cryptocrunch: "CryptoCrunchApp invited you to the Airdrop!",
			//   bitcointalk: "Hey BitcoinTalk, welcome to Morpher!",
			//   ghayas: "Ghayas invited to the Morpher Airdrop!",
			//   picpukk: "PICPUKK invited you to the Morpher Airdrop!",
			//   traderjh: "Welcome, you've been invited to the Morpher Airdrop!",
			//   gift: "Exclusive offer to claim the Morpher Airdrop.",
			//   altcoinpost: "Welcome Altcoinpost fans to the Morpher Airdrop!",
			//   federicosellitti: "Federico invited you to the Morpher Airdrop!",
			//   cryptogrowth: "Welcome CryptoGrowth fans to the Morpher Airdrop!",
			//   webleone: "Welcome Webleone fans to the Morpher Airdrop!",
			//   coinfunda: "Welcome CoinFunda fans to the Morpher Airdrop!",
			//   redeeem: "Welcome Redeeem users to the Morpher Airdrop!",
			//   neconnected: "Welcome, you've been invited to the Morpher Airdrop!",
			//   tetrisotoko: "Tetris invited you to the Morpher Airdrop!",
			//   julesquinha: "Julesquinha invited you to the Morpher Airdrop!",
			//   oespanhol: "O Espanhol invited you to the Morpher Airdrop!",
			//   // Our own custom links
			//   digitalking: "Digital King invited you to the Morpher Airdrop!",
			//   mjonas: "Maiyer invited you to the Morpher Airdrop!",
			//   pbauer: "Pieter invited you to the Morpher Airdrop!",
			//   steffanie: "Steffanie invited you to the Morpher Airdrop!",
			//   chimaok: "Chima invited you to the Morpher Airdrop!",
			//   martin: "Martin invited you to the Morpher Airdrop!",
			//   draper: "Welcome to the Morpher Airdrop!",
			//   telegram: "Welcome to the Morpher Airdrop!",
			//   quora: "Welcome to the Morpher Airdrop!",
			//   discord: "Welcome to the Morpher Airdrop!",
			//   vk: "Welcome to the Morpher Airdrop!",
			//   custom_invite: "Welcome to the Morpher Airdrop!",
			// };

			// const customRussianGroups = {
			//   "privet-1": "CUSTOM",
			//   "privet-2": "CUSTOM",
			//   "privet-3": "CUSTOM",
			//   "privet-4": "CUSTOM",
			//   "privet-5": "CUSTOM",
			//   "nov1kov_bm": "CUSTOM",
			// };

			// let lang = "";
			// let langGroup;

			// const existsEn = Object.keys(customGroups).includes(this.customInvite);
			// const existsRu = Object.keys(customRussianGroups).includes(
			//   this.customInvite
			// );

			// if (existsEn) {
			//   lang = "en";
			//   langGroup = customGroups;
			// } else if (existsRu) {
			//   lang = "ru";
			//   langGroup = customRussianGroups;
			// }

			let invite_data;
			if (this.custom_invite_list && this.custom_invite_list.length > 0) {
				try {
					invite_data = this.custom_invite_list.find(
						(invite) => invite.custom_invite == this.customInvite
					);
				} catch (err) {
					this.custom_invite_list = [];
				}
			}

			switch (type) {
				case "exists":
					return {
						lang: invite_data ? invite_data.language || "en" : "en",
						exists: invite_data ? true : false,
					};
				case "message":
					return invite_data ? invite_data.custom_text || "" : "";
				default:
					break;
			}
		},
	},
	head() {
		return {
			title: {
				inner: this.headParams.title,
			},
			meta: [
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				{ property: "og:type", content: "website", id: "og-type" },
				{
					property: "og:title",
					content: this.headParams.title,
					id: "og-title",
				},
				{
					property: "og:description",
					content: this.headParams.description,
					id: "og-description",
				},
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_airdrop_fb.png",
					id: "og-image",
				},
				{
					property: "og:url",
					content: "https://www.morpher.com" + this.$route.path,
					id: "og-url",
				},
				{
					property: "twitter:card",
					content: "summary_large_image",
					id: "twitter-card",
				},
				{
					property: "twitter:site",
					content: "@morpher_io",
					id: "twitter-site",
				},
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_airdrop_tw.png",
					id: "twitter-image",
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
};
</script>
